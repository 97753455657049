import { z } from 'zod';

import auth from '@udacity/ureact-hoth';

import { publicEnv } from '~/features/environment/public';
import { logger } from '~/features/logging/logger';
import { ErrorBase } from '~/utils/error-base';
import { queryUdacityService, UdacityServiceError } from '~/utils/query-udacity-service';

export const DELETE_ENROLLMENT_POLICY_ERROR = 'POLICY_ERROR';
export class DeleteEnrollmentError extends ErrorBase<typeof DELETE_ENROLLMENT_POLICY_ERROR> {}

const errorResponseSchema = z.object({
  error: z.string(),
});

// Unenrolls the user from a program. Throws a DeleteEnrollmentError if the action failed due to a policy error.
export async function deleteEnrollment(programKey: string): Promise<void> {
  const jwt = auth.getJWT();
  const userId = auth.getCurrentUserId();

  if (!userId || !jwt) {
    logger.fatal('User is not authenticated', {
      file: 'src/features/program/controllers/delete-enrollment.ts',
      function: 'deleteEnrollment',
      error: {
        jwt,
        userId,
      },
    });

    throw new Error('User is not authenticated');
  }

  try {
    return await queryUdacityService({
      url: `${publicEnv.NEXT_PUBLIC_ENROLLMENTS_URL}/v1/enrollments/${programKey}`,
      method: 'DELETE',
      zodSchema: null,
      authorization: `Bearer ${jwt}`,
    });
  } catch (error) {
    if (error instanceof UdacityServiceError) {
      const parseResult = errorResponseSchema.safeParse(error.data);
      if (parseResult.success && parseResult.data.error === 'policy disallows unenrollment') {
        throw new DeleteEnrollmentError({
          name: DELETE_ENROLLMENT_POLICY_ERROR,
          message: 'policy disallows unenrollment',
        });
      }
    }
    throw error;
  }
}
