import { Middleware } from 'next-api-route-middleware';

import { logger } from '~/features/logging/logger';

export const captureErrors: Middleware = async (req, res, next) => {
  try {
    await next();
  } catch (error: any) {
    logger.fatal('API route error caught.', {
      file: 'src/features/api/middleware/capture-errors.ts',
      function: 'captureErrors',
      reqUrl: req.url,
      reqBody: req.body,
      error,
    });

    res.status(500).send({ message: 'Server error!' });
  }
};
