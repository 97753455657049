import { z } from 'zod';

export const scopeSchema = z.enum(['all', 'favorite', 'enrolled']);

export const DEFAULT_SORT = 'most-popular';

// Sort options when searching for programs.
export const searchNameValues = ['relevance', DEFAULT_SORT, 'highest-rated', 'recently-updated'] as const;

// Sort options when browsing programs.
export const catalogNameValues = [DEFAULT_SORT, 'highest-rated', 'recently-updated'] as const;
export const sortNameSchema = z.enum(searchNameValues).catch(DEFAULT_SORT);

export const filtersSchema = z
  .object({
    scope: scopeSchema.optional(),
    price: z.string().optional(),
    school: z.string().optional(),
    skill: z.string().optional(),
    difficulty: z.string().optional(),
    duration: z.string().optional(),
    rawDuration: z.string().optional(),
    type: z.string().optional(),
    availability: z.string().optional(),
  })
  .transform((data) => ({
    scope: data.scope?.split(',') as Scope[],
    price: data.price?.split(','),
    school: data.school?.split(','),
    skill: data.skill?.split(','),
    difficulty: data.difficulty?.split(','),
    duration: data.duration?.split(','),
    rawDuration: data.rawDuration?.split(','),
    type: data.type?.split(','),
    availability: data.availability?.split(','),
  }));

export type Scope = z.infer<typeof scopeSchema>;
export type Filters = z.infer<typeof filtersSchema>;
export type SortName = z.infer<typeof sortNameSchema>;

export type UriFilter = {
  name: string;
  uri: string;
};
