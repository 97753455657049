// Normalize an email and hash it using SHA-256.
export async function hashEmailClientSide(email: string): Promise<string> {
  try {
    const normalizedEmail = email.trim().toLowerCase();
    const encoder = new TextEncoder();
    const data = encoder.encode(normalizedEmail);
    const hashBuffer = await window.crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashString = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
    return hashString;
  } catch (error) {
    console.warn('browser does not implement WebCrypto', error);
    return '';
  }
}
