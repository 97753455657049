import { usePathname } from 'next/navigation';
import { useQuery } from 'react-query';

import { getExperimentVariantOnClient } from '../utils';

export function useClientExperiment(
  flagKey: string,
  { enabled = true, pathRegex }: { enabled?: boolean; pathRegex?: RegExp } = {}
) {
  const pathname = usePathname();
  const isEnabledOnPath = pathRegex ? pathRegex.test(pathname) : true;
  const { isLoading, data: variant } = useQuery(
    ['clientExperiment', flagKey],
    () => getExperimentVariantOnClient({ flagKey }),
    { enabled: enabled && isEnabledOnPath }
  );
  return { isLoading, variant };
}
