import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { blockedCountryCodes } from '~/cf-worker/utils';
import { matcher } from '~/utils/matcher';

import { Promotion } from '../models/promotions';

dayjs.extend(utc);

export function checkStartAndEndDateUTC(promo: Promotion | null): Promotion | null {
  if (!promo?.startDateTime || !promo?.endDateTime) {
    return null;
  }
  const nowUtc = dayjs.utc();
  const startUtc = dayjs(promo.startDateTime);
  const endUtc = dayjs(promo.endDateTime);
  if (nowUtc.isAfter(startUtc) && nowUtc.isBefore(endUtc)) return promo;
  return null;
}

export function checkGeoRestrictions(envName: string, geoCode?: string) {
  return (promo?: Promotion | null): Promotion | null => {
    if (!promo) return null;
    if (!promo.geoRestrictions?.allowList && !promo.geoRestrictions?.blockList) return promo;
    // Note: if there are Geo Restrictions but no `geoCode` present, we block the promo.
    else if (!geoCode) {
      if (envName === 'development') {
        console.warn(
          `!!WARNING: Geo restrictions enabled but no geoCode present. Blocking promo ${promo.promotionId}!!`
        );
      }
      return null;
    }

    const findMatch = (target: string, patterns: string[]) =>
      matcher(target, patterns, { enableRegexpMatching: Boolean(promo.geoRestrictions?.enableRegexpMatching) });

    if (promo.geoRestrictions.blockList && promo.geoRestrictions.allowList) {
      return findMatch(geoCode, promo.geoRestrictions.allowList) && !findMatch(geoCode, promo.geoRestrictions.blockList)
        ? promo
        : null;
    }

    if (promo.geoRestrictions.blockList) {
      return !findMatch(
        geoCode,
        promo.geoRestrictions.blockList.flatMap((code) => {
          if (code === 'DEFAULT_ACCENTURE_EXCLUSIONS') return blockedCountryCodes;
          return code;
        })
      )
        ? promo
        : null;
    }

    if (promo.geoRestrictions.allowList) {
      return findMatch(
        geoCode,
        promo.geoRestrictions.allowList.flatMap((code) => {
          if (code === 'DEFAULT_ACCENTURE_EXCLUSIONS') return blockedCountryCodes;
          return code;
        })
      )
        ? promo
        : null;
    }

    return promo;
  };
}

export function checkCurrencyRestrictions(envName: string, currency?: string) {
  return (promo?: Promotion | null): Promotion | null => {
    if (!promo) return null;
    if (!promo.currencyRestrictions?.allowList && !promo.currencyRestrictions?.blockList) return promo;
    // Note: if there are Currency Restrictions but no `currency` present, we block the promo.
    else if (!currency) {
      if (envName === 'development') {
        console.warn(
          `!!WARNING: Currency restrictions enabled but no currency present. Blocking promo ${promo.promotionId}!!`
        );
      }
      return null;
    }

    const findMatch = (target: string, patterns: string[]) =>
      matcher(target, patterns, { enableRegexpMatching: Boolean(promo.currencyRestrictions?.enableRegexpMatching) });

    if (promo.currencyRestrictions.blockList && promo.currencyRestrictions.allowList) {
      return findMatch(currency, promo.currencyRestrictions.allowList) &&
        !findMatch(currency, promo.currencyRestrictions.blockList)
        ? promo
        : null;
    }

    if (promo.currencyRestrictions.blockList) {
      return !findMatch(currency, promo.currencyRestrictions.blockList) ? promo : null;
    }

    if (promo.currencyRestrictions.allowList) {
      return findMatch(currency, promo.currencyRestrictions.allowList) ? promo : null;
    }

    return promo;
  };
}

export function checkRouteRestrictions(pathname: string) {
  return (promo?: Promotion | null): Promotion | null => {
    if (!promo || !pathname) return null;
    if (!promo.routeRestrictions) return promo;
    const findMatch = (target: string, patterns: string[]) =>
      matcher(target, patterns, { enableRegexpMatching: Boolean(promo.routeRestrictions?.enableRegexpMatching) });
    if (promo.routeRestrictions.blockList && promo.routeRestrictions.allowList) {
      return findMatch(pathname, promo.routeRestrictions.allowList) &&
        !findMatch(pathname, promo.routeRestrictions.blockList)
        ? promo
        : null;
    }

    if (promo.routeRestrictions.blockList) {
      return !findMatch(pathname, promo.routeRestrictions.blockList) ? promo : null;
    }

    if (promo.routeRestrictions.allowList) {
      return findMatch(pathname, promo.routeRestrictions.allowList) ? promo : null;
    }

    return promo;
  };
}
