import { useRouter } from 'next/router';

// known top-level nav items in udacity-header
export enum NavItemId {
  Catalog = 'catalog',
  Dashboard = 'dashboard',
  Government = 'government',
  Outcomes = 'outcomes',
  Plans = 'plans',
  Sessions = 'sessions',
}

const BASE_ROUTE_ID_MAP = {
  catalog: NavItemId.Catalog,
  dashboard: NavItemId.Dashboard,
  government: NavItemId.Government,
  'career-outcomes': NavItemId.Outcomes,
  plans: NavItemId.Plans,
  sessions: NavItemId.Sessions,
};

export function useActiveRouteId() {
  const router = useRouter();
  const route = router.route;
  const baseSegment = route.split('/')[1];

  // First try exact match from map
  const exactMatch = BASE_ROUTE_ID_MAP[baseSegment as keyof typeof BASE_ROUTE_ID_MAP];
  if (exactMatch) {
    return exactMatch;
  }

  // Handle enterprise catalog case
  if (route.startsWith('/org/[companySlug]/catalog')) {
    return NavItemId.Catalog;
  }

  // No match found
  return '';
}
