import { Env, HttpHeader, NewCookie, RequestCookies } from './types';

/**
 * Searches both the request cookies and new cookies for a cookie with the given name, for the given value.
 * Starts with the request cookies since it's an object and is faster to search.
 * If the cookie is not found in the request cookies, it will search the new cookies.
 */
export function searchCookiesForValue(
  requestCookies: RequestCookies,
  newCookies: NewCookie[],
  cookieName: string,
  value: string
): boolean {
  // 1. If the user has an existing request cookie, we can check that value
  const foundRequestCookie = requestCookies[cookieName];
  if (foundRequestCookie) {
    return foundRequestCookie === value;
  }

  // 2. If the cookie isn't found in the request cookies, we check newCookies
  const foundInNewCookies = newCookies.find((newCookie) => newCookie.name === cookieName);
  if (foundInNewCookies) {
    return foundInNewCookies.value === value;
  }

  // Not found
  return false;
}

// Same as above but just checks for the cookies existence
export function searchCookies(requestCookies: RequestCookies, newCookies: NewCookie[], cookieName: string): boolean {
  // 1. If the user has an existing request cookie
  const foundRequestCookie = requestCookies[cookieName];
  if (foundRequestCookie) {
    return true;
  }

  // 2. If the cookie isn't found in the request cookies, we check newCookies
  const foundInNewCookies = newCookies.find((newCookie) => newCookie.name === cookieName);
  if (foundInNewCookies) {
    return true;
  }

  // Not found
  return false;
}

// Same as above but just checks for the cookies existence
export function getValueFromCookies(
  requestCookies: RequestCookies,
  newCookies: NewCookie[],
  cookieName: string
): string | undefined {
  // 1. If the user has an existing request cookie
  const foundRequestCookie = requestCookies[cookieName];
  if (foundRequestCookie) {
    return foundRequestCookie;
  }

  // 2. If the cookie isn't found in the request cookies, we check newCookies
  const foundInNewCookies = newCookies.find((newCookie) => newCookie.name === cookieName);
  if (foundInNewCookies) {
    return foundInNewCookies.value;
  }
}

/**
 * Queries a Udacity service from Cloudflare.
 */
export async function queryUdacityServiceFromCloudflare({
  jwt,
  url,
  body,
  env,
  method = 'POST',
}: {
  jwt: string;
  url: string;
  body?: string;
  env: Env;
  method?: string;
}) {
  if (env.envName === 'test') {
    throw new Error('Cannot query Udacity service in test environment.');
  }
  try {
    const response = await fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${jwt}`,
      },
      body,
    });
    const data = (await response.json()) as any;

    return data;
  } catch (e) {
    console.error(e);
    return {};
  }
}

/**
 * Checks if the given string ends with a dot followed by something excluding a slash.
 */
export function endsWithDotSomething(inputString: string): boolean {
  const regexPattern = /\.\w+$/;
  return regexPattern.test(inputString);
}

/**
 * Used to prepend the `/test` segment to the URL if the user is part of an experiment.
 */
export function prependSegmentToUrl(url: URL, segment: string): URL {
  const updatedURL = new URL(url.href);
  updatedURL.pathname = `/${segment}${url.pathname}`;
  return updatedURL;
}

/**
 * Returns a URL to an experiment based on our experiment naming convention.
 * Example: https://www.udacity.com/plans - becomes - https://www.udacity.com/test/plans--variation-x
 */
export function getExperimentUrl(
  url: URL,
  { variationKey, experimentKey }: { variationKey: string; experimentKey: string }
): URL {
  const basePath = `/test/${experimentKey}/${variationKey}`;
  const pathnameSegments = url.pathname.split('/').filter(Boolean);
  let newPathname: string | undefined;

  if (pathnameSegments.length > 0) {
    newPathname = `${basePath}/${pathnameSegments.join('/')}`;
  } else {
    newPathname = basePath;
  }

  const transformedURL = new URL(url.href);
  transformedURL.pathname = newPathname;

  return transformedURL;
}

export function getFreeTrialHomepageUrl(
  url: URL,
  experiment: { variationKey: string; experimentKey: string } | null
): URL {
  const updatedURL = new URL(url.href);
  updatedURL.pathname = '/alt-homepage/free-trial';
  if (experiment) {
    return getExperimentUrl(updatedURL, experiment);
  }
  return updatedURL;
}

export function getTakeOverHomepageUrl(
  url: URL,
  experiment: { variationKey: string; experimentKey: string } | null
): URL {
  const updatedURL = new URL(url.href);
  updatedURL.pathname = '/alt-homepage/takeover';
  if (experiment) {
    return getExperimentUrl(updatedURL, experiment);
  }
  return updatedURL;
}

/**
 * Dispatches a track event to Segment
 */
export async function dispatchSegmentTrackEvent({
  segmentKey,
  payload,
}: {
  segmentKey: string;
  payload: Record<string, any>;
}) {
  console.info(`Sending Segment event: ${JSON.stringify(payload, null, 2)}`);

  const res = await fetch('https://api.segment.io/v1/track', {
    method: 'POST',
    headers: {
      Authorization: `Basic ${segmentKey}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  const data = await res.json();

  console.info(JSON.stringify(data, null, 2));
}

export type ForwardedHeaders =
  | {
      'X-Forwarded-Proto': string;
      'X-Forwarded-Host': string;
    }
  | {
      'X-Forwarded-Proto': string;
      'X-Forwarded-Host': string;
      'X-Forwarded-For': string;
    };

/**
 * Gets the X-Forwarded headers from the request.
 */
export function getForwardedHeaders(request: Request) {
  const url = new URL(request.url);
  const ip = request.headers.get('CF-Connecting-IP');
  const forwardedHeaders = {
    ['X-Forwarded-Proto']: url.protocol,
    ['X-Forwarded-Host']: url.host,
    ...(ip && { ['X-Forwarded-For']: ip }),
  };
  return forwardedHeaders;
}

/**
 * A utility function to set headers on to a request.
 */
export function setRequestHeader(request: Request, header: HttpHeader) {
  for (const [key, value] of Object.entries(header)) {
    if (value) {
      request.headers.set(key, value);
    }
  }
}

export function withSecurityHeaders(response: Response): Response {
  // Note: setting the content-security-policy header to prevent clickjacking attacks.
  response.headers.set('Content-Security-Policy', "frame-ancestors 'self' *.udacity.com;");
  return response;
}

export const blockedCountryCodes = [
  'AF',
  'AX',
  'AL',
  'DZ',
  'AS',
  'AD',
  'AO',
  'AG',
  'AR',
  'AM',
  'AW',
  'AZ',
  'BS',
  'BH',
  'BD',
  'BB',
  'BY',
  'BZ',
  'BJ',
  'BM',
  'BT',
  'BO',
  'BA',
  'BW',
  'BN',
  'BG',
  'BF',
  'BI',
  'CV',
  'KH',
  'CM',
  'KY',
  'CF',
  'CL',
  'CG',
  'CR',
  'CI',
  'HR',
  'CW',
  'CY',
  'CZ',
  'DK',
  'DJ',
  'DO',
  'EC',
  'SV',
  'EE',
  'ET',
  'FJ',
  'FI',
  'PF',
  'GA',
  'GM',
  'GE',
  'GH',
  'GI',
  'GU',
  'GT',
  'GG',
  'GY',
  'HT',
  'HN',
  'HU',
  'IS',
  'ID',
  'IQ',
  'IM',
  'JM',
  'JE',
  'JO',
  'KZ',
  'KE',
  'KW',
  'KG',
  'LA',
  'LV',
  'LB',
  'LS',
  'LY',
  'LI',
  'LT',
  'LU',
  'MO',
  'MK',
  'MG',
  'MW',
  'MY',
  'MV',
  'ML',
  'MT',
  'MQ',
  'MR',
  'MU',
  'MD',
  'MC',
  'MN',
  'ME',
  'MA',
  'MZ',
  'MM',
  'NA',
  'NP',
  'NC',
  'NZ',
  'NI',
  'NE',
  'NO',
  'PK',
  'PS',
  'PA',
  'PG',
  'PY',
  'PE',
  'PH',
  'PR',
  'CG',
  'RU',
  'RW',
  'LC',
  'MF',
  'ST',
  'SN',
  'RS',
  'SL',
  'SX',
  'SK',
  'SI',
  'SO',
  'SS',
  'LK',
  'SD',
  'SR',
  'TJ',
  'TZ',
  'TG',
  'TT',
  'TN',
  'TM',
  'UG',
  'UA',
  'UM',
  'UY',
  'UZ',
  'VE',
  'VN',
  'VG',
  'VI',
  'YE',
  'ZM',
  'ZW',
  'SY',
  'KP',
  'IR',
  'CU',
];
