import { MouseEvent, useMemo, useRef } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';

import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Progress,
  Skeleton,
  Stack,
  Tag,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  Difficulty1Icon,
  Difficulty2Icon,
  Difficulty3Icon,
  MoreHorizontalIcon,
  TimeIcon,
} from '@udacity/chakra-uds-icons';
import { BreakpointValue, focusStyles } from '@udacity/chakra-uds-theme';

import { ButtonLink } from '~/components/button-link';
import { PlayButton } from '~/components/play-button';
import { Rating } from '~/components/rating';
import { SubscriptionPausedModal } from '~/features/dashboard/components/subscription-paused-modal';
import { publicEnv } from '~/features/environment/public';
import { FavoriteButton } from '~/features/favorites/components/favorite-button';
import { FavoriteMenuItem } from '~/features/favorites/components/favorite-menu-item';
import { i18n } from '~/features/internationalization/internationalization';
import { Subscription } from '~/features/payment/models/subscription';
import {
  DELETE_ENROLLMENT_POLICY_ERROR,
  deleteEnrollment,
  DeleteEnrollmentError,
} from '~/features/program/controllers/delete-enmrollment';
import { useEnrollments } from '~/features/program/hooks/use-enrollments';
import { useEnrollmentsProgress } from '~/features/program/hooks/use-enrollments-progress';
import { useIsConnectLearner } from '~/features/sessions/hooks/use-is-connect-learner';
import { useUser } from '~/features/user/hooks/use-user';
import { useClientRender } from '~/hooks/use-client-render';
import { useDurationChangeExperiment } from '~/hooks/use-duration-change-experiment';
import { useI18n } from '~/hooks/use-i18n';
import { extractCompanySlug } from '~/utils/extract-company-slug';

import { CatalogCardItem } from '../models/catalog-card-item';
import { CatalogItem } from '../models/catalog-item';
import { CatalogSearchItem } from '../models/catalog-search-result';

type CatalogCardWithCatalogItem = {
  catalogItem: CatalogItem | CatalogCardItem | CatalogSearchItem;
  basicItem?: never;
  width?: string | BreakpointValue<string>;
  minWidth?: string | BreakpointValue<string>;
  grow?: boolean;
  priority?: boolean;
  imageQuality?: number;
  subscription?: Subscription | null;
  hasIncompleteRequiredAssessment?: boolean;
  learningPlanAssessmentStepId?: string;
  onClick?: (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => void;
};

type CatalogCardWithBasicItem = {
  catalogItem?: never;
  basicItem: {
    title: string;
    key: string;
  };
  width?: string | BreakpointValue<string>;
  minWidth?: string | BreakpointValue<string>;
  priority?: boolean;
  grow?: boolean;
  subscription?: never;
  hasIncompleteRequiredAssessment?: boolean;
  learningPlanAssessmentStepId?: string;
  onClick?: (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => void;
};

export function CatalogCard(props: CatalogCardWithCatalogItem | CatalogCardWithBasicItem) {
  const { t } = useI18n();
  const router = useRouter();
  const hasCatalogItem = isCatalogCardWithCatalogItem(props);
  const { userId, userCatalog } = useUser();
  const { isConnectLearner } = useIsConnectLearner();
  const companySlug = router.query.companySlug ?? extractCompanySlug(userCatalog?.catalogUrl);
  const { enrollment, enrollmentsIsLoading } = useEnrollments(
    hasCatalogItem ? props.catalogItem.key : props.basicItem.key
  );
  const { isRawDuration, isLoading: isLoadingDuration } = useDurationChangeExperiment();
  let durationText: string | null = null;
  if (props.catalogItem && !isLoadingDuration) {
    durationText = isRawDuration ? props.catalogItem.rawDurationDisplay : props.catalogItem.duration;
  }
  const hasReviews = hasCatalogItem ? props.catalogItem.reviewSummary.numberOfReviews > 0 : false;
  const clientRender = useClientRender();
  const { enrollmentsProgress } = useEnrollmentsProgress();

  const programProgress = enrollmentsProgress?.programs.find(
    (program) => program.key === (hasCatalogItem ? props.catalogItem.key : props.basicItem.key)
  );

  const isReadyForGraduation =
    enrollment &&
    enrollment.isReadyForGraduation &&
    !enrollment.isGraduated &&
    'isGraduationEnabled' in enrollment &&
    enrollment.isGraduationEnabled;

  const { subscription, hasIncompleteRequiredAssessment, learningPlanAssessmentStepId } = props;
  const isConsumerSubscriptionPaused = subscription?.status === 'paused' && enrollment?.state === 'PAUSED';
  const formattedResumeDate = subscription?.resumeAt
    ? new Date(subscription?.resumeAt).toLocaleDateString(i18n.language)
    : '';
  const { isOpen: isPausedModalOpen, onOpen: openPausedModal, onClose: closePausedModal } = useDisclosure();

  const fallbackImage = '/images/program-fallbacks/8.png';
  const enterpriseData = hasCatalogItem && 'enterprise' in props.catalogItem && props.catalogItem.enterprise;

  const Tags = useMemo(() => {
    const baseStyles = {
      borderRadius: '4px',
      padding: '4px 8px',
      variant: 'no-stroke',
    };
    if (enrollment?.learningPlans) {
      return enrollment.learningPlans.map((learningPlan) => (
        <Tag key={learningPlan.id} backgroundColor='white' {...baseStyles}>
          {learningPlan.shortTitle}
        </Tag>
      ));
    }
    if (!enrollment && enterpriseData && enterpriseData.applicationIds.length > 0 && enterpriseData.enrollable) {
      return (
        <Tag backgroundColor='gray.300' {...baseStyles}>
          {t('program.applyToBegin')}
        </Tag>
      );
    }
    if (!enrollment && enterpriseData && enterpriseData.applicationIds.length > 0 && !enterpriseData.enrollable) {
      return (
        <Tag backgroundColor='gray.300' {...baseStyles}>
          {t('program.applyForAccess')}
        </Tag>
      );
    }
    if (!enrollment && enterpriseData && enterpriseData.enrollable) {
      return (
        <Tag backgroundColor='white' {...baseStyles}>
          {t('program.enrollNow')}
        </Tag>
      );
    }
    if (!enrollment && enterpriseData && !enterpriseData.enrollable) {
      return (
        <Tag backgroundColor='gray.300' {...baseStyles}>
          {t('program.requestAccess')}
        </Tag>
      );
    }
    return null;
  }, [enrollment, enterpriseData, t]);

  const url = useMemo(() => {
    if (enrollmentsIsLoading) return undefined;
    if (enrollment) return `/enrollment/${enrollment.key}`;
    // Hardcode the url for ACI learning plan
    if (
      props.catalogItem &&
      'semanticType' in props.catalogItem &&
      props.catalogItem?.semanticType === 'Learning Plan' &&
      props.catalogItem?.title?.includes('ACI')
    ) {
      return '/aws-cloud-institute';
    }
    if (hasCatalogItem) {
      let pathname = props.catalogItem?.slug
        ? `/course/${props.catalogItem.slug}`
        : `/course/--${props.catalogItem.key}`;
      if (companySlug) {
        pathname += `?companySlug=${companySlug}`;
      }
      return pathname;
    }
    return undefined;
  }, [enrollment, enrollmentsIsLoading, hasCatalogItem, props.catalogItem, companySlug]);

  return (
    <Box
      as='article'
      bg='white'
      border='1px solid'
      borderColor='gray.200'
      borderRadius='6px'
      flex={props.grow ? { base: 'unset', xl: 1 } : 'unset'}
      flexShrink={0}
      height='292px'
      minWidth={props.minWidth}
      p={1}
      position='relative'
      role='group'
      transition='all 0.3s ease-in-out'
      width={props.width}
      _hover={{
        boxShadow: 'lg',
      }}
      onClick={props.onClick}
    >
      <Flex flexDir='column' minHeight='100%'>
        <Flex
          borderTopRadius='base'
          padding='8px'
          {...(focusStyles('blue.500') as any)}
          flex='0 0 100px'
          height='100px'
          overflow='hidden'
        >
          <Box
            bgGradient='linear(to-b, transparent, white, transparent)'
            bottom={0}
            height='120%'
            mixBlendMode='soft-light'
            opacity={0.3}
            pointerEvents='none'
            position='absolute'
            right={0}
            transform={`translateX(50%) translateY(100%) rotate(-20deg)`}
            transition='transform 0.3s ease-in-out'
            width='200%'
            zIndex={1}
            _groupHover={{
              transform: `translateX(10%) translateY(-10%) rotate(-20deg)`,
            }}
          />
          {hasCatalogItem && props.catalogItem.image && typeof props.catalogItem.image === 'string' && (
            <Image
              alt=''
              priority={Boolean(props.priority)}
              quality={100}
              sizes='442px'
              src={props.catalogItem.image}
              style={{ objectFit: 'cover', borderRadius: '4px', pointerEvents: 'none' }}
              fill
            />
          )}
          {!props.catalogItem?.image && (
            <Image
              alt=''
              priority={Boolean(props.priority)}
              quality={100}
              sizes='442px'
              src={fallbackImage}
              style={{ objectFit: 'cover', borderRadius: '4px', pointerEvents: 'none' }}
              fill
            />
          )}
        </Flex>

        <Flex flex={1} flexDir='column' marginBottom='-4px' marginLeft='-4px' marginRight='-4px' p={3}>
          <Heading
            as='a'
            color='black'
            fontFamily='body'
            fontSize='md'
            fontWeight='600'
            href={url}
            lineHeight='1.375rem'
            noOfLines={!enrollment && hasCatalogItem && hasReviews ? 3 : 4}
            sx={{
              '&::before': {
                display: 'block',
                content: '""',
                position: 'absolute',
                inset: 0,
                zIndex: 1,
              },
            }}
          >
            {hasCatalogItem ? props.catalogItem.title : props.basicItem.title}
          </Heading>

          {!enrollment && hasCatalogItem && (hasReviews || durationText || props.catalogItem.difficultyLevel) && (
            <Stack marginTop='auto' spacing='12px'>
              {hasReviews && (
                <Flex gap='4px'>
                  <Rating color='feedback.warning' rating={props.catalogItem.reviewSummary.starsAverage} size='sm' />
                  <Text color='gray.700' fontWeight='600' size='caption'>
                    ({props.catalogItem.reviewSummary.numberOfReviews})
                  </Text>
                </Flex>
              )}
              <Flex borderTop='1px solid' borderTopColor='gray.100' flexDir='column' pt={3}>
                <Flex alignItems='center' color='gray.700' flexBasis='18px' fontWeight='500' gap={1}>
                  {durationText && (
                    <>
                      <TimeIcon />
                      <Text size='caption' textTransform='capitalize'>
                        {durationText}
                      </Text>
                    </>
                  )}
                </Flex>
                <Flex alignItems='center' color='gray.700' flexBasis='18px' fontWeight='500' gap={1}>
                  {props.catalogItem.difficultyLevel && (
                    <>
                      {(props.catalogItem.difficultyLevel === 'Beginner' ||
                        props.catalogItem.difficultyLevel === 'Discovery' ||
                        props.catalogItem.difficultyLevel === 'Fluency') && <Difficulty1Icon />}
                      {props.catalogItem.difficultyLevel === 'Intermediate' && <Difficulty2Icon />}
                      {props.catalogItem.difficultyLevel === 'Advanced' && <Difficulty3Icon />}
                      <Text size='caption'>{props.catalogItem.difficultyLevel}</Text>
                    </>
                  )}
                </Flex>
              </Flex>
            </Stack>
          )}

          {enrollment && (
            <Flex flex='1' flexDir='column'>
              <Flex alignItems='flex-end' flex='1' gap='8px' marginTop='auto'>
                {!isReadyForGraduation && isConsumerSubscriptionPaused && (
                  <Box position='relative' zIndex={2}>
                    <SubscriptionPausedModal
                      isOpen={isPausedModalOpen}
                      paymentDate={formattedResumeDate}
                      onClose={closePausedModal}
                    />
                    <PlayButton
                      ariaLabel={t('settings.subscription.resumeSubscription')!}
                      overrideColor='gray.500'
                      onClick={openPausedModal}
                    />
                  </Box>
                )}
                {!isReadyForGraduation && !isConsumerSubscriptionPaused && !programProgress?.complete && (
                  <Box position='relative' zIndex={2}>
                    <PlayButton
                      href={`${enrollment.classroomLink}/resume-learning`}
                      overrideColor='blue.500'
                      ariaLabel={
                        t('program.continueProgram', {
                          programName: hasCatalogItem ? props.catalogItem.title : props.basicItem.title,
                        })!
                      }
                    />
                  </Box>
                )}
                <Stack alignItems='flex-start' flex={1} spacing='4px' width='100%'>
                  <Progress
                    aria-label={t('program.progressIs', { percent: programProgress?.completion ?? 0 })!}
                    bg='gray.200'
                    data-testid='catalog-card-progress-bar'
                    size='sm'
                    value={programProgress?.completion}
                    width='100%'
                    css={{
                      '[role="progressbar"]': {
                        background: 'blue.500',
                      },
                      pointerEvents: 'none',
                    }}
                  />
                  <Text color='black' fontWeight='600' size='small-label'>
                    {t('common.percent', { val: programProgress?.completion })}
                  </Text>
                  {!isReadyForGraduation &&
                    !isConsumerSubscriptionPaused &&
                    programProgress?.complete &&
                    hasIncompleteRequiredAssessment &&
                    learningPlanAssessmentStepId && (
                      <ButtonLink
                        buttonProps={{
                          colorScheme: 'sea-foam',
                          width: '100%',
                          mt: '4px',
                          size: 'sm',
                          zIndex: 2,
                        }}
                        linkProps={{
                          href: `${publicEnv.NEXT_PUBLIC_LEARN_URL}/lp-step/${learningPlanAssessmentStepId}`,
                        }}
                      >
                        {t('learningPlan.startAssessment')}
                      </ButtonLink>
                    )}
                </Stack>
              </Flex>
              {isReadyForGraduation && (
                <Button
                  as='a'
                  colorScheme='sea-foam'
                  href={`${publicEnv.NEXT_PUBLIC_LEARN_URL}/graduation/${enrollment.key}`}
                  mt='8px'
                  size='sm'
                  w='full'
                  zIndex={2}
                >
                  {t('program.graduateNow')}
                </Button>
              )}
            </Flex>
          )}
        </Flex>
      </Flex>

      {!!Tags && (
        <Flex
          flexWrap='wrap'
          gap='8px'
          left='12px'
          pointerEvents='none'
          position='absolute'
          right='62px'
          top='12px'
          zIndex={1}
        >
          {Tags}
        </Flex>
      )}

      {userId && clientRender && (
        <Box position='absolute' right='12px' top='12px' zIndex={2}>
          {enrollment && !isConnectLearner ? (
            <OptionsMenu programKey={hasCatalogItem ? props.catalogItem.key : props.basicItem.key} />
          ) : (
            <FavoriteButton programKey={hasCatalogItem ? props.catalogItem.key : props.basicItem.key} />
          )}
        </Box>
      )}
    </Box>
  );
}

export function CatalogCardSkeleton({ width = '190px' }: { width?: string | BreakpointValue<string> }) {
  return (
    <Flex
      bg='blue.800'
      borderRadius='base'
      flexDirection='column'
      flexShrink={0}
      height='292px'
      minWidth='152px'
      overflow='hidden'
      padding={1}
      width={width}
    >
      <Skeleton borderRadius='base' flex='0 1 104px' />
      <Stack flex='1' padding={2} spacing='24px'>
        <Stack spacing='4px'>
          <Skeleton h='16px' />
          <Skeleton h='16px' width='65%' />
        </Stack>
        <Flex alignItems='center' gap={1} mt='auto'>
          <Rating isLoading={true} size='sm' />
          <Skeleton h='12px' width='24px' />
        </Flex>
        <Flex flexDir='column' gap={2}>
          <Flex gap={1}>
            <Skeleton h='12px' width='15px' />
            <Skeleton h='12px' width='56px' />
          </Flex>
          <Flex gap={1}>
            <Skeleton h='12px' width='15px' />
            <Skeleton h='12px' width='64px' />
          </Flex>
        </Flex>
      </Stack>
    </Flex>
  );
}

export function isCatalogCardWithCatalogItem(
  catalogCardProps: CatalogCardWithCatalogItem | CatalogCardWithBasicItem
): catalogCardProps is CatalogCardWithCatalogItem {
  return catalogCardProps.catalogItem !== undefined;
}

export function OptionsMenu({ programKey }: { programKey: string }) {
  const { t } = useI18n();
  const toast = useToast();
  const router = useRouter();
  const containerRef = useRef<HTMLDivElement>(null);

  async function handleRemoveEnrollment() {
    try {
      await deleteEnrollment(programKey);
      router.replace(router.asPath);

      toast({
        variant: 'success',
        title: t('program.enrollmentRemoved'),
      });
    } catch (error) {
      if (error instanceof DeleteEnrollmentError && error.name === DELETE_ENROLLMENT_POLICY_ERROR) {
        toast({
          variant: 'error',
          title: t('program.errorRemovingEnrollmentBlockedByPolicy'),
        });
        return;
      }
      toast({
        variant: 'error',
        title: t('program.errorRemovingEnrollment'),
      });
    }
  }

  function handleMenuClose() {
    // when closing the menu it will add focus to the card causing the carousel to slide putting the card in the first position
    // to prevent this we force the focus to go to the next or prev button
    // @ts-ignore
    containerRef.current?.closest('.swiper')?.querySelector('.swiper-button-container button:not([disabled])')?.focus();
  }

  return (
    <Box ref={containerRef}>
      <Menu placement='bottom-end' onClose={handleMenuClose}>
        <MenuButton
          _hover={{ bgColor: 'rgba(11 ,11 ,11 ,0.9)' }}
          aria-label='Enrollment options'
          as={IconButton}
          bgColor='rgba(11, 11, 11, 0.6)'
          border='solid 2px'
          borderColor='white'
          color='white'
          icon={<MoreHorizontalIcon height='24px' width='24px' />}
          size='sm'
          variant='solid'
          zIndex={2}
          isRound
        />
        <Portal>
          <MenuList fontSize='sm'>
            <FavoriteMenuItem programKey={programKey} />
            <MenuItem py='4px' onClick={handleRemoveEnrollment}>
              {t('program.removeEnrollment')}
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    </Box>
  );
}
