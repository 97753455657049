import { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { Box, IconButton, Tooltip, useToast } from '@chakra-ui/react';
import { HeartEmptyIcon, HeartIcon } from '@udacity/chakra-uds-icons';

import { useUser } from '~/features/user/hooks/use-user';
import { useI18n } from '~/hooks/use-i18n';

import { useFavorites } from '../hooks/use-favorites';

export function FavoriteButton({ programKey, buttonSize = 'sm' }: { programKey: string; buttonSize?: 'sm' | 'md' }) {
  const { t } = useI18n();
  const { userId } = useUser();
  const toast = useToast();
  const { favorites, addFavorite, removeFavorite, favoritesIsFetched } = useFavorites();
  const [isFavorited, setIsFavorited] = useState<Boolean>();

  useEffect(() => {
    // Only set the initial state, after that it is optimistically updated
    if (!favorites || isFavorited !== undefined) return;

    setIsFavorited(favorites.includes(programKey));
  }, [favorites, isFavorited, programKey]);

  function handleClick() {
    if (!userId) return;

    setIsFavorited((prev) => !prev);
    isFavorited ? removeFavorite.mutate({ userId, programKey }) : addFavorite.mutate({ userId, programKey });

    toast({
      variant: 'success',
      title: t('program.favoriteUpdated'),
    });
  }

  if (!favoritesIsFetched) return null;

  return (
    <Tooltip label={isFavorited ? t('program.removeFavorite') : t('program.favorite')} variant='light' hasArrow>
      <IconButton
        _hover={{ bgColor: 'rgba(11 ,11 ,11 ,0.9)' }}
        aria-label='Add program to your favorites'
        bgColor='rgba(11, 11, 11, 0.6)'
        border='solid 2px'
        borderColor='white'
        color='white'
        icon={<Icon buttonSize={buttonSize} isFavorited={Boolean(isFavorited)} />}
        size={buttonSize}
        variant='solid'
        isRound
        onClick={handleClick}
      />
    </Tooltip>
  );
}

function Icon({ isFavorited, buttonSize }: { isFavorited: boolean; buttonSize: 'sm' | 'md' }) {
  const heartSize = buttonSize === 'sm' ? '20px' : '24px';
  return (
    <AnimatePresence mode='wait'>
      {isFavorited && (
        <Box
          key='heart-full'
          animate={{ scale: 1, transition: { duration: 0.1 } }}
          as={motion.div}
          display='flex'
          exit={{ scale: 0, transition: { duration: 0.1 } }}
          initial={{ scale: 0 }}
        >
          <HeartIcon color='accent.lime' h={heartSize} w={heartSize} />
        </Box>
      )}
      {!isFavorited && (
        <Box
          key='heart-empty'
          animate={{ scale: 1, transition: { duration: 0.1 } }}
          as={motion.div}
          display='flex'
          exit={{ scale: 0, transition: { duration: 0.1 } }}
          initial={{ scale: 0 }}
        >
          <HeartEmptyIcon h={heartSize} w={heartSize} />
        </Box>
      )}
    </AnimatePresence>
  );
}
