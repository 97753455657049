import { useEffect, useState } from 'react';

import { MenuItem, useToast } from '@chakra-ui/react';

import { useUser } from '~/features/user/hooks/use-user';
import { useI18n } from '~/hooks/use-i18n';

import { useFavorites } from '../hooks/use-favorites';

export function FavoriteMenuItem({ programKey }: { programKey: string }) {
  const { t } = useI18n();
  const toast = useToast();
  const { userId } = useUser();
  const { favorites, addFavorite, removeFavorite, favoritesIsFetched } = useFavorites();
  const [isFavorited, setIsFavorited] = useState<Boolean>();

  useEffect(() => {
    // Only set the initial state, after that it is optimistically updated
    if (!favorites || isFavorited !== undefined) return;

    setIsFavorited(favorites.includes(programKey));
  }, [favorites, isFavorited, programKey]);

  function handleClick() {
    if (!userId) return;

    try {
      isFavorited ? removeFavorite.mutate({ userId, programKey }) : addFavorite.mutate({ userId, programKey });
      setIsFavorited((prev) => !prev);

      toast({
        variant: 'success',
        title: t('program.favoriteUpdated'),
      });
    } catch (error) {
      toast({
        variant: 'error',
        title: t('program.errorUpdatingFavorites'),
      });
    }
  }

  if (!favoritesIsFetched) return null;

  return (
    <MenuItem py='4px' onClick={handleClick}>
      {Boolean(isFavorited) ? t('program.removeFavorite') : t('program.favorite')}
    </MenuItem>
  );
}
