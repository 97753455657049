import { useEffect } from 'react';
import cookie from 'js-cookie';

import { COURSE_DURATION_COOKIE } from '~/features/cookies/constants';
import { setClientCookie } from '~/features/cookies/utils/set-client-cookie';
import { useClientExperiment } from '~/features/experiments/hooks/use-client-experiment';
import { useExperiment } from '~/features/experiments/hooks/use-experiment';

import { useClientRender } from './use-client-render';

// Fetch the variant from cookie, server, and client in that order. Return as soon as one is found.
export function useDurationChangeExperiment() {
  const isClient = useClientRender();
  const cookieVariant = isClient ? cookie.get(COURSE_DURATION_COOKIE) : undefined;
  const serverVariant = useExperiment()?.ab_course_duration;
  const { isLoading: isClientVariantLoading, variant: clientVariant } = useClientExperiment('ab_course_duration', {
    enabled: !cookieVariant && !serverVariant,
  });

  const variant = cookieVariant ?? serverVariant ?? clientVariant;

  useEffect(() => {
    if (variant) {
      setClientCookie(COURSE_DURATION_COOKIE, variant, 7);
    }
  }, [variant]);

  const isRawDuration = variant == 'variant_b';
  const isOldDuration = !isRawDuration;
  // If we are able to get the variant from cookie or server, then it is considered loaded.
  const isLoading = !cookieVariant && !serverVariant && isClientVariantLoading;

  return {
    isLoading,
    isRawDuration,
    isOldDuration,
  };
}
