import { NextApiResponse } from 'next';
import { CookieSerializeOptions, serialize } from 'cookie';

import { publicEnv } from '~/features/environment/public';

/**
 * Used to set a cookie from api routes.
 */

export const setServerCookie = (res: NextApiResponse, name: string, value: unknown, expires: Date) => {
  const stringValue = typeof value === 'object' ? 'j:' + JSON.stringify(value) : String(value);

  const options: CookieSerializeOptions = {
    path: '/',
    expires,
    domain: publicEnv.NEXT_PUBLIC_ENVIRONMENT_NAME === 'test' ? undefined : '.udacity.com',
    secure: publicEnv.NEXT_PUBLIC_ENVIRONMENT_NAME === 'production',
  };

  res.setHeader('Set-Cookie', serialize(name, stringValue, options));
};
