import type { NextApiResponse } from 'next';
import { use } from 'next-api-route-middleware';
import { flow, partition, sortBy } from 'lodash-es';
import { z } from 'zod';

import { captureErrors } from '~/features/api-routes/middleware/capture-errors';
import { NextApiRequestWithAuth } from '~/features/api-routes/types';
import {
  ACTIVE_SUBSCRIPTION_COOKIE,
  COUPON_COOKIE,
  HAS_FOCUSED_EXPERIENCE_COOKIE,
  JWT_COOKIE,
  PROMO_COOKIE,
  SKIP_PROMO_COOKIE,
  USER_TYPE_COOKIE,
  VIEWER_COUNTRY_COOKIE,
  VIEWER_CURRENCY_COOKIE,
} from '~/features/cookies/constants';
import { setServerCookie } from '~/features/cookies/utils/set-server-cookie';
import { isPersonalizedDiscountCoupon } from '~/features/discount/utils/is-pd-coupon';
import { publicEnv } from '~/features/environment/public';
import { getPromotionsFromServer } from '~/features/promotions/controllers/get-promotions-from-server';
import { Promotion, sanitizedPromoSchema } from '~/features/promotions/models/promotions';
import {
  checkCurrencyRestrictions,
  checkGeoRestrictions,
  checkRouteRestrictions,
  checkStartAndEndDateUTC,
} from '~/features/promotions/utils/promo-restriction-evaluators';

async function handler(req: NextApiRequestWithAuth, res: NextApiResponse) {
  // Request Cookies
  const currency = req.cookies[VIEWER_CURRENCY_COOKIE];
  const viewerCountry = req.cookies[VIEWER_COUNTRY_COOKIE];
  const hasActiveSubscription = req.cookies[ACTIVE_SUBSCRIPTION_COOKIE] === 'true';
  const priorityPromoCookie = req.cookies[PROMO_COOKIE]; // Note: promo is a cookie used to force a promo for a user.
  const skipPromoCookie = req.cookies[SKIP_PROMO_COOKIE];
  const couponCodeCookie = req.cookies[COUPON_COOKIE];
  const jwtCookie = req.cookies[JWT_COOKIE];
  const hasFocusedCookie = req.cookies[HAS_FOCUSED_EXPERIENCE_COOKIE];
  const userTypeCookie = req.cookies[USER_TYPE_COOKIE] as 'consumer' | 'enterprise' | undefined;
  // Query Params
  const pathname = req.query.pathname as string;
  const promoFromQueryParam = req.query.promo as string | undefined;
  const promoParticipationQueryParam = req.query.promo_participation as string | undefined;
  const forcedPromoQueryParam = req.query.force_promo as string | undefined;
  const isFreeTrialEnabled = (req.query.is_free_trial_enabled as string | undefined) === 'true';

  const hasFocused = hasFocusedCookie === 'true';

  const debugMsgs: string[] = [];
  const isDebug = req.query.debug === 'true' || req.cookies['debug'] === 'true';
  addMsg(isDebug, debugMsgs, 'Using the following parameters to determine the promotion:');
  addMsg(isDebug, debugMsgs, `  viewerCountry: ${viewerCountry}`);
  addMsg(isDebug, debugMsgs, `  hasActiveSubscription: ${hasActiveSubscription}`);
  addMsg(isDebug, debugMsgs, `  priorityPromoCookie: ${priorityPromoCookie}`);
  addMsg(isDebug, debugMsgs, `  skipPromoCookie: ${skipPromoCookie}`);
  addMsg(isDebug, debugMsgs, `  couponCodeCookie: ${couponCodeCookie}`);
  addMsg(isDebug, debugMsgs, `  jwtCookie: ${jwtCookie ? 'present' : 'undefined'}`);
  addMsg(isDebug, debugMsgs, `  hasFocusedCookie: ${hasFocusedCookie}`);
  addMsg(isDebug, debugMsgs, `  userTypeCookie: ${userTypeCookie}`);
  addMsg(isDebug, debugMsgs, `  pathname: ${pathname}`);
  addMsg(isDebug, debugMsgs, `  promoFromQueryParam: ${promoFromQueryParam}`);
  addMsg(isDebug, debugMsgs, `  promoParticipationQueryParam: ${promoParticipationQueryParam}`);
  addMsg(isDebug, debugMsgs, `  forcedPromoQueryParam: ${forcedPromoQueryParam}`);
  addMsg(isDebug, debugMsgs, `  isFreeTrialEnabled: ${isFreeTrialEnabled}`);
  addMsg(isDebug, debugMsgs, '');

  if (promoParticipationQueryParam === 'skip') {
    if (!skipPromoCookie) {
      setServerCookie(res, SKIP_PROMO_COOKIE, 'true', new Date(Date.now() + 1000 * 60 * 60)); // Note: 1 hour
      addMsg(isDebug, debugMsgs, 'Set skip promo cookie.');
    }
  }

  if (!pathname) {
    return respond(res, 400, { message: 'Missing query param pathname.' }, debugMsgs, isDebug);
  }

  const activePromos = await getPromotionsFromServer();
  addMsg(isDebug, debugMsgs, `Fetched ${activePromos.length} active promotions.`);

  if (publicEnv.NEXT_PUBLIC_ENVIRONMENT_NAME !== 'production' && forcedPromoQueryParam) {
    const forcedPromo = activePromos.find((promo) => promo.promotionId === forcedPromoQueryParam);
    if (forcedPromo) {
      addMsg(isDebug, debugMsgs, `Forced promo found: ${forcedPromo.promotionId}`);
      return respond(res, 200, { promo: sanitizedPromoSchema.parse(forcedPromo) }, debugMsgs, isDebug);
    } else {
      addMsg(isDebug, debugMsgs, 'No forced promo found.');
      return respond(res, 200, { promo: null }, debugMsgs, isDebug);
    }
  }

  // Note: promotions with priority < 0 are considered private promotions that are only available based on the `promo` query param or the `promo` cookie value.
  const [publicPromotions, privatePromotions] = partition(activePromos, (promo) => promo.priority > -1);
  const priorityPromotionId = promoFromQueryParam ?? priorityPromoCookie;

  const withStandardRestrictions = flow(
    (promo) => {
      const result = checkPromoParticipation(promoParticipationQueryParam === 'skip' || skipPromoCookie === 'true')(
        promo
      );
      if (result) {
        addMsg(isDebug, debugMsgs, '');
        addMsg(isDebug, debugMsgs, `Evaluating ${promo?.promotionId}:`);
      }
      addEvalResult(isDebug, debugMsgs, 'participation', !!result, promo);
      return result;
    },
    (promo) => {
      const result = checkRouteRestrictions(pathname)(promo);
      addEvalResult(isDebug, debugMsgs, 'route', !!result, promo);
      return result;
    },
    (promo) => {
      const result = checkStartAndEndDateUTC(promo);
      addEvalResult(isDebug, debugMsgs, 'date range', !!result, promo);
      return result;
    },
    (promo) => {
      const result = checkAudienceRestrictions({
        hasActiveSubscription,
        isAuthenticated: Boolean(jwtCookie),
        hasFocused,
        userType: userTypeCookie,
      })(promo);
      addEvalResult(isDebug, debugMsgs, 'audience', !!result, promo);
      return result;
    },
    (promo) => {
      const result = checkGeoRestrictions(publicEnv.NEXT_PUBLIC_ENVIRONMENT_NAME, viewerCountry)(promo);
      addEvalResult(isDebug, debugMsgs, 'region', !!result, promo);
      return result;
    },
    (promo) => {
      const result = checkCurrencyRestrictions(publicEnv.NEXT_PUBLIC_ENVIRONMENT_NAME, currency)(promo);
      addEvalResult(isDebug, debugMsgs, 'currency', !!result, promo);
      return result;
    },
    (promo) => {
      const result = checkPromoCodeApplied(couponCodeCookie)(promo);
      addEvalResult(isDebug, debugMsgs, 'promo code', !!result, promo);
      return result;
    }
  );

  // Note: first check if there are any private promotions
  // Note: private promotions can only be activated with a `forcedPromotionId` query param or a `promo` cookie value.
  const targetPrivatePromo = privatePromotions.find(
    flow(checkForcedPromo(priorityPromotionId, { strict: true }), withStandardRestrictions)
  );

  const freeTrialPromotionEntry = withStandardRestrictions(findActiveFreeTrialEntry(privatePromotions));

  if (!targetPrivatePromo && isFreeTrialEnabled && freeTrialPromotionEntry) {
    addMsg(isDebug, debugMsgs, 'Free trial promotion found.');
    return respond(res, 200, { promo: sanitizedPromoSchema.parse(freeTrialPromotionEntry) }, debugMsgs, isDebug);
  }

  if (targetPrivatePromo) {
    setServerCookie(res, PROMO_COOKIE, targetPrivatePromo.promotionId, new Date(targetPrivatePromo.endDateTime));
    addMsg(isDebug, debugMsgs, `Private promo found: ${targetPrivatePromo.promotionId}`);
    return respond(res, 200, { promo: sanitizedPromoSchema.parse(targetPrivatePromo) }, debugMsgs, isDebug);
  }

  // If we have two or more promotions with the same priority value, sort by `_id` to make results deterministic.
  const sortedPromos = sortBy(publicPromotions, ['priority', '_id']).reverse(); // Note: we need to reverse sort by descending order

  addMsg(isDebug, debugMsgs, `Promo evaluation order: ${sortedPromos.map((promo) => promo.promotionId).join(', ')}.`);

  const targetPublicPromo = sortedPromos.find(flow(withStandardRestrictions));

  if (targetPublicPromo) {
    if (priorityPromotionId === targetPublicPromo.promotionId) {
      setServerCookie(res, PROMO_COOKIE, targetPublicPromo.promotionId, new Date(targetPublicPromo.endDateTime));
    }
    addMsg(isDebug, debugMsgs, `Public promo found: ${targetPublicPromo.promotionId}`);
    return respond(res, 200, { promo: sanitizedPromoSchema.parse(targetPublicPromo) }, debugMsgs, isDebug);
  }

  addMsg(isDebug, debugMsgs, 'No promo found.');
  return respond(res, 200, { promo: null }, debugMsgs, isDebug);
}

export default use(captureErrors, handler);

function checkForcedPromo(forcedPromoId?: string, { strict = false }: { strict?: boolean } = {}) {
  return (promo?: Promotion | null): Promotion | null => {
    if (!promo) return null;
    else if (forcedPromoId) return promo.promotionId === forcedPromoId ? promo : null;
    else {
      return strict ? null : promo; // Note: if strict mode is on, we block promos that are not forced.
    }
  };
}

function checkAudienceRestrictions({
  hasActiveSubscription,
  isAuthenticated,
  hasFocused,
  userType,
}: {
  hasActiveSubscription: boolean;
  isAuthenticated: boolean;
  hasFocused: boolean;
  userType?: 'consumer' | 'enterprise';
}) {
  return (promo?: Promotion | null): Promotion | null => {
    if (!promo) return null;
    switch (promo.audienceRestrictions) {
      default:
      case 'all':
        return promo;
      case 'subscribed':
        return hasActiveSubscription ? promo : null;
      case 'non-subscribed':
        // Note: a non-subscribed user could have a free account or no account at all.
        // Must be a non-enterprise user to see promotion.
        return hasActiveSubscription || userType === 'enterprise' ? null : promo;
      case 'free-access':
        // Note: a free access user has an account but is not yet subscribed to an all access plan.
        return isAuthenticated && !hasActiveSubscription ? promo : null;
      case 'focus-users':
        return hasFocused ? promo : null;
    }
  };
}

function checkPromoCodeApplied(promoCode?: string) {
  return (promo?: Promotion | null): Promotion | null => {
    if (!promo) return null;
    else if (!promoCode) return promo;
    else {
      if (promo.skipPromoWhenCouponCookied) {
        switch (promo.type) {
          case 'personalized-discount':
            return isPersonalizedDiscountCoupon(promoCode) ? null : promo;
          case 'standard-promotion':
          default:
            // Note: here if we already have the promoCode applied, we no longer want to return the promotion to the user.
            return promo.promoCode === promoCode ? null : promo;
        }
      }
      return promo;
    }
  };
}

function checkPromoParticipation(skip: boolean) {
  return (promo?: Promotion | null): Promotion | null => {
    if (!promo) return null;
    if (skip) {
      // Note: we only want to skip consumer promotions. Not site wide announcements.
      return promo?.type === 'announcement' ? promo : null;
    }
    return promo;
  };
}

function findActiveFreeTrialEntry(promotions: Promotion[]) {
  return promotions.find((promo) => promo.promotionId === '7-day-free-trial-2024' && promo.isActive) || null;
}

function addEvalResult(
  isDebug: boolean,
  messages: string[],
  checkName: string,
  isPassed: boolean,
  promo?: Promotion | null
) {
  if (isDebug && promo) {
    messages.push(`  ${isPassed ? 'passed' : 'failed'} ${checkName} check.`);
  }
}

function addMsg(isDebug: boolean, messages: string[], message: string) {
  if (isDebug) {
    messages.push(message);
  }
}

function respond(res: NextApiResponse, status: number, data: object, debugMsgs: string[], isDebugMode: boolean) {
  if (isDebugMode) {
    return res.status(status).json({ ...data, debug: debugMsgs });
  } else {
    return res.status(status).json(data);
  }
}

export const promoApiResponseSchema = z.object({
  promo: sanitizedPromoSchema.nullable(),
  debug: z.array(z.string()).optional(),
});

export type PromoApiResponse = z.infer<typeof promoApiResponseSchema>;
