import { z } from 'zod';

import { publicEnv } from '~/features/environment/public';

import promotionsData from '../../../../promotions-data.json';
import { promotionSchema } from '../models/promotions';

export async function getPromotionsFromServer() {
  // Note: in non production environments, we can just fetch directly
  if (publicEnv.NEXT_PUBLIC_ENVIRONMENT_NAME !== 'production') {
    const { getPromotions } = await import('~/features/promotions/controllers/get-promotions');
    // Note: on preview/staging environments, we want to be less strict so stakeholders can force promotions when building promotions in Sanity.
    return getPromotions({ activeOnly: false });
  }
  return z.array(promotionSchema).parse(promotionsData.promotions);
}
